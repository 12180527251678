@import "./../../styles/abstracts/variables";

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin: 0.5rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &__success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
    a {
      text-decoration: none;
      color: #0f5132;
      font-weight: 700;
      transition: 0.3s;
    }
    a:hover {
      color: dodgerblue;
    }
  }
  &__error {
    color: #842029;
    background-color: #f8d7da;
    a {
      text-decoration: none;
      color: #842029;
      font-weight: 700;
      transition: 0.3s;
      margin-left: 5px;
      margin-right: 5px;
    }
    a:hover {
      color: dodgerblue;
    }
  }
  &__info {
    color: #055160;
    background-color: #cff4fc;
    border-color: #b6effb;
    a {
      text-decoration: none;
      color: #055160;
      font-weight: 700;
      transition: 0.3s;
    }
    a:hover {
      color: dodgerblue;
    }
  }
  &__notice {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
    a {
      text-decoration: none;
      color: #664d03;
      font-weight: 700;
      transition: 0.3s;
    }
    a:hover {
      color: dodgerblue;
    }
  }
}
