.hoverable-span{
    transition: .3s;
    &:hover {
        color: dodgerblue;
    }
}
.hoverable-span-denger{
    transition: .3s;
    &:hover {
        color: red;
    }
}