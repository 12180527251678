.fallback-error {
    margin: 2rem;
    padding: 1rem;
    background-color: whitesmoke;
    font-size: 1.5rem;
}

.fallback-error > pre { 
    color: firebrick;
    font-size: 1rem;
}

.fallback-error > button {
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 10px;
    margin-top: 1rem;
}