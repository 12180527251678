.dr-ltr {
  direction: ltr;
}
.dr-ltr-right {
  direction: ltr;
  text-align: right;
}
.dr-ltr-right-inb {
  direction: ltr;
  text-align: right;
  display: inline-block;
}
.dr-ltr-left {
  direction: ltr;
  text-align: left;
}

option.dr-ltr-left {
  direction: ltr !important;
  text-align: center !important;
}
.dr-ltr-center {
  direction: ltr;
  text-align: center;
}

html[dir="rtl"] select option,
html[dir="rtl"] .choices__input {
  direction: rtl;
}

.PhoneInputCountry {
  margin-left: 0.35em;
  .PhoneInputCountrySelectArrow {
    margin-right: 0.35em;
  }
  select option {
    direction: ltr !important;
    text-align: left !important;
    padding-left: 5px !important;
  }
}

.font-main {
  font-family: "Noto Sans Arabic", sans-serif !important;
}
.font-kufi {
  font-family: "Noto Kufi Arabic", sans-serif !important;
}

.font-cairo {
  font-family: "Cairo", sans-serif !important;
}

.none-styled-list {
  list-style: none;
}

.ql-editor {
  text-align: right !important;
}
.ql-editor li::before {
  width: 2.2em !important;
}


.modal-open {
  padding-left: 0 !important;
  padding-right: 17px !important;
}

.span-number {
  display: inline-block;
  direction: ltr;
}