@import "./../../styles/abstracts/variables";

.small-spinner {
  position: relative;
  height: 30px;
  width: 30px;
  display: inline-block;
  margin: 3px;
  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 3px solid lightgray;
    border-top-color: dodgerblue;
    animation: my-spinner 1.2s linear infinite;
  }
}

@keyframes small-spinner {
  to {
    transform: rotate(360deg);
  }
}
