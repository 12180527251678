@import "./../../styles/abstracts/variables";

.my-spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-top: -30px;
  margin-left: -30px;
  border-radius: 50%;
  border: 3px solid lightgray;
  border-top-color: dodgerblue;
  animation: my-spinner 1.2s linear infinite;
}

@keyframes my-spinner {
  to {
    transform: rotate(360deg);
  }
}


.group-spinner-dopback {
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  visibility: hidden;
  transition: 0.5s ease-in-out;
  .visible {
    visibility: visible;
  }
  .group-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
}


.group-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}