.rating {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
    .rating-stars {

        direction: ltr;
    }
  p {
    margin-top: 4px;
    line-height: 1.4;
    color: #666;
  }
}
