.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 9999;
  font-family: Arial, Helvetica, sans-serif;
}

.top-right {
  top: 12px;
  right: 12px;
  animation: toast-in-right 0.7s;
}

.bottom-right {
  bottom: 12px;
  right: 12px;
  animation: toast-in-right 0.7s;
}

.top-left {
  top: 12px;
  left: 12px;
  animation: toast-in-left 0.7s;
}

.bottom-left {
  bottom: 12px;
  left: 12px;
  animation: toast-in-left 0.7s;
}

.notification {
  background: #fff;
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 20px;
  margin-bottom: 15px;
  width: 300px;
  max-height: 100px;
  border-radius: 3px;
  box-shadow: 0 0 10px #999;
  color: #000;
  opacity: 0.9;
}

.notification:hover {
  box-shadow: 0 0 12px #fff;
  opacity: 1;
  cursor: pointer;
}

.notification-title {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 6px;
  width: 300px;
  height: 18px;
}

.notification-message {
  margin: 0;
  text-align: left;
  height: 18px;
  margin-left: -1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notification-image {
  float: left;
  margin-right: 15px;
  font-size: 36px;
}

.my-toast {
  //height: 50px;
  //width: 365px;
  color: #fff;
  padding: 20px 15px 10px 10px;
}

.close-button {
  position: absolute;
  top: 4px;
  right: 8px;
  font-size: 24px;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    translate: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    translate: translateX(0);
  }
}
