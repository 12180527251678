.navbar {
    height: 70px;
}
.navbar-logo {
    font-family: "Noto Kufi Arabic", sans-serif !important;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 30px;
    position: relative;
    font-weight: bold;
    cursor: pointer;
    img {
        width: 36px;
        height: 36px;
    }
    span {
        color: #555;
    }
}